import { Injectable } from '@angular/core';

import { WindowReferenceAppService } from './window-reference-app.service';
import { IGTMCustomDimensionApp } from 'src/interfaces/IGTMCustomDimensionApp';
import { AcessoIpAppModel } from 'src/models/acessoIpAppModel';


@Injectable({
  providedIn: 'root'
})

// este serviço foi criado com o proposito de tratar o window para o GoogleTagManager (GTM)
// vide https://itbusinesshub.com/blog/integrate-google-tag-manager-in-angular-app/
export class DataLayerAppService {
  private window;
  _flAceiteDesempenho: boolean = true;
  _flAceitePublicidade: boolean = true;
  _acessoIp: AcessoIpAppModel = new AcessoIpAppModel();

  constructor(private _windowRef: WindowReferenceAppService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
  }

  private pingHome(obj: any) {
    if (obj && this.window) this.window.dataLayer?.push(obj);
  }


  private verificarPermissoesCookies() {
    this._flAceiteDesempenho = true;
    this._flAceitePublicidade = true;
    // se nao existir na localStorage ou se as flags na localStorage estiverem nulas ou true
    // vai permitir rastreamento tag Manager
    // so nao vai permitir se estiverem explicitamente como 0 (false)
    if (localStorage.getItem('PasseioJaApp:AcessoIp')) {
      this._acessoIp = JSON.parse(localStorage.getItem('PasseioJaApp:AcessoIp')!);
      if (this._acessoIp.flAceiteDesempenho == 0
        || this._acessoIp.flAceitePublicidade == 0) {
        this._flAceiteDesempenho = false;
        this._flAceitePublicidade = false;
      }
    }
  }

  //list of all our dataLayer methods
  logPageView(url: string) {
    this.verificarPermissoesCookies();
    if (this._flAceiteDesempenho && this._flAceitePublicidade) {
      const hit = {
        event: 'content-view',
        pageName: url
      };
      this.pingHome(hit);
    }
  }

  logEvent(event: string, category: string, action: string, label: string) {
    this.verificarPermissoesCookies();
    if (this._flAceiteDesempenho && this._flAceitePublicidade) {
      const hit = {
        event: event,
        category: category,
        action: action,
        label: label
      }
      this.pingHome(hit);
    }
  }

  logCustomDimension(value: IGTMCustomDimensionApp) {
    this.verificarPermissoesCookies();
    if (this._flAceiteDesempenho && this._flAceitePublicidade) {
      const hit = {
        event: 'custom-dimension',
        value: value
      }
      this.pingHome(hit);
    }
  }

  // .. add more custom methods as needed by your app.
}
