import { Injectable } from '@angular/core';

function getWindow():any {
    return window;
}

@Injectable({
    providedIn: 'root'
})

// este serviço foi criado com o proposito de tratar o window para o GoogleTagManager (GTM)
// chamado pelo DataLayerAppService
export class WindowReferenceAppService {
    get nativeWindow() {
        return getWindow();
    }
}
